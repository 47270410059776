import { useEffect, useState, useRef } from 'react';
import { useRouter } from 'next/router';
import { useCollectionByHandle } from '@backpackjs/storefront';
import { useProductsFromHandles, useScrollDirection } from '@hooks';
import { BuilderContext } from './BundleBuildercontext';
import BuilderHeader from './BuilderHeader';
import BuilderGridHeader from './BuilderGridHeader';
import BuilderFilters from './BuilderFilters';
import BuilderGrid from './BuilderGrid';
import BuilderSteps from './BuilderSteps';
import BuilderPagination from './BuilderPagination';
import BuilderLoadMore from './BuilderLoadMore';
import BuilderQuickView from './BuilderQuickView';
import BuilderFreeGiftToast from './BuilderFreeGiftToast';

export function BundleBuilder({ cms }) {
  const router = useRouter();
  const bundleBuilder = useRef(null);
  const isBrowser = typeof window !== 'undefined';
  const header = isBrowser ? document.querySelector('.site-header') : null;
  const scrollDirection = useScrollDirection();
  const urlSearchParams = new URLSearchParams(isBrowser ? document.location.search : '');
  const [builderSettings, setBuilderSettings] = useState({
    campaign: cms.campaign,
    title: cms.title,
    description: cms.description,
    ctaTitle: cms.ctaTitle,
    disclaimer: cms.disclaimer,
    collection: cms.collection,
    filters: cms.filters || [],
    hideHeader: cms.hideHeader,
    bundleSteps: cms.bundleSteps || [],
    defaultBundleMessage: cms.defaultBundleMessage,
    pageLimit: 24,
    enableLoadMore: cms.enableLoadMore || false,
    scrollThreshold: cms.scrollThreshold || 0,
    desktopBgImage: cms.desktopBgImage,
    mobileBgImage: cms.mobileBgImage,
    darkText: cms.darkText || false,
    heroProduct: cms.heroProduct?.handle,
  });

  const urlSelectedProducts = urlSearchParams.get('selectedProducts') || '';

  const [builderData, setBuilderData] = useState({
    products: [],
    freeGift: null,
    filteredProducts: [],
    selectedProducts: [],
    activeFilter: urlSearchParams.get('activeFilter') || '',
    page: urlSearchParams.get('page') ? Number.parseInt(urlSearchParams.get('page'), 10) : 1,
    quickViewProduct: null,
  });

  const headerHidden = builderSettings.hideHeader && scrollDirection === 'down';
  const { collection } = useCollectionByHandle({
    handle: builderSettings?.collection,
  });

  const productHandles = collection?.products
    ?.filter((product) => product.status === 'ACTIVE')
    .map((product) => product.handle);

  const { products: productsData, success: productsLoaded } =
    useProductsFromHandles({
      handles: productHandles || [],
    });

  const scrollTop = () => {
    bundleBuilder?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const updateBuilderData = (data) => {
    setBuilderData((currentData) => {
      const newData = { ...currentData, ...data };
      const queryData = {}
      if (
        data.page &&
        data.page !== currentData.page &&
        !builderSettings.enableLoadMore
      ) {
        scrollTop();
      }
      if (cms.enableUrlHistory) {
        if (newData.page) {
          queryData.page = newData.page;
        }

        if (newData.selectedProducts.length) {
          const selectedProductsHandles = newData.selectedProducts.map(
            (product) => product.handle
          );

          queryData.selectedProducts = selectedProductsHandles.join(',');
        }

        if (newData.activeFilter) {
          queryData.activeFilter = newData.activeFilter;
        }

        if (router.query.handle) {
          queryData.handle = router.query.handle;
        }

        const searchParams = new URLSearchParams(queryData);

        const customState = {
          ...window.history.state,
          url: router.pathname,
          as: router.pathname.replace('[handle]', router.query.handle) + '?' + searchParams.toString(),
        }

        window.history.replaceState(customState, null, customState.as);
      }

      return { ...newData };
    });
  };

  const updateBuilderSettings = (data) => {
    setBuilderSettings((currentData) => {
      return {
        ...currentData,
        ...data,
      }
    });
  };

  const applyFilter = (products) => {
    const filter = builderData.activeFilter;
    let filteredProducts = products;

    if (filter !== '' && filter !== undefined) {
      filteredProducts = products.filter((product) => {
        return product.tags?.some((tag) => tag === filter);
      });
    }

    if (builderSettings.heroProduct) {
      filteredProducts = products.filter((product) => {
        return product.handle !== builderSettings.heroProduct;
      });
    }

    return filteredProducts;
  };

  const applyPagination = (productList) => {
    const { pageLimit } = builderSettings;
    const { page } = builderData;
    let start = 0;
    if (!builderSettings.enableLoadMore) {
      start = (page - 1) * pageLimit;
    }
    const end = page * pageLimit;
    const paginatedProducts = productList.slice(start, end);
    return paginatedProducts;
  };

  useEffect(() => {
    const products = Object.keys(productsData).map((key) => productsData[key]);
    const filteredProducts = applyFilter(products);
    const paginatedProducts = applyPagination(filteredProducts);
    let selectedProductsHandles = urlSelectedProducts.split(',') || '';
    if (builderSettings.heroProduct) {
      selectedProductsHandles = [
        builderSettings.heroProduct,
        ...selectedProductsHandles.slice(1, selectedProductsHandles.length),
      ];
    }
    let selectedProducts = products.filter((product) => {
      return selectedProductsHandles.includes(product.handle);
    });

    selectedProducts = selectedProducts.sort((a, b) => {
      if (a.handle == builderSettings.heroProduct) {
        return -1;
      }
      return 1;
    })

    setBuilderData((currentData) => {
      return {
        ...currentData,
        products,
        filteredProducts,
        paginatedProducts,
        selectedProducts,
      };
    });
  }, [productsLoaded]);

  useEffect(() => {
    const filteredProducts = applyFilter(builderData.products);
    const paginatedProducts = applyPagination(filteredProducts);

    setBuilderData((currentData) => {
      return {
        ...currentData,
        filteredProducts,
        paginatedProducts,
      };
    });
  }, [builderData.activeFilter]);

  useEffect(() => {
    const filteredProducts = applyFilter(builderData.products);
    const paginatedProducts = applyPagination(filteredProducts);
    setBuilderData((currentData) => {
      return {
        ...currentData,
        paginatedProducts,
      };
    });
  }, [builderData.page]);

  useEffect(() => {
    const currentScroll = window.scrollY;
    if (header && builderSettings.hideHeader) {
      if (scrollDirection === 'up') {
        header.style.top = '0';
      } else {
        const headerHeight = `-${header.offsetHeight}px`;
        header.style.top = headerHeight;
      }
    }
  }, [scrollDirection]);

  return (
    <BuilderContext.Provider
      value={{
        builderSettings,
        updateBuilderSettings,
        builderData,
        updateBuilderData,
      }}
    >
      <style
        dangerouslySetInnerHTML={{
          __html:
            '@media (max-width: 1024px) {#chat-button { z-index: 1 !important }}',
        }}
      ></style>
      <BuilderHeader scrollTop={scrollTop} />
      <div className="bundle-builder__experience-container container mx-auto mt-11 mb-11 flex flex-col px-5 lg:flex-row lg:flex-nowrap">
        <div
          className={`bundle-builder__grid-container w-full lg:w-3/4
            ${headerHidden ? 'scroll-m-0' : 'scroll-m-24 lg:scroll-m-28'}
          `}
          ref={bundleBuilder}
        >
          <BuilderGridHeader />
          <BuilderFilters />
          <BuilderGrid />
          {builderSettings.enableLoadMore === true ? (
            <BuilderLoadMore />
          ) : (
            <BuilderPagination />
          )}
        </div>
        <div
          className={`bundle-builder__sidebar-container sticky bottom-0 z-10 mt-11 w-full self-start transition-all duration-150 lg:bottom-auto lg:mt-0 lg:w-1/4 lg:pl-5 ${
            headerHidden ? 'lg:top-5' : 'lg:top-[105px]'
          }`}
        >
          <BuilderSteps />
        </div>
      </div>
      {builderData.quickViewProduct && <BuilderQuickView />}
      <BuilderFreeGiftToast key="builderToast" headerHidden={headerHidden} />
    </BuilderContext.Provider>
  );
}

BundleBuilder.displayName = 'Bundle Builder';

BundleBuilder.Schema = {
  category: 'Modules',
  label: BundleBuilder.displayName,
  key: 'BundleBuilder',
  fields: [
    {
      component: 'text',
      name: 'campaign',
      label: 'Campaign',
      description: 'Reference to allow Gift with purchase campaign to be applied to the current bundle builder experience',
    },
    {
      component: 'text',
      name: 'title',
      label: 'Title',
      description:
        'Builder title displayed at the top of the experience, if not populated the title will not be rendered',
    },
    {
      component: 'markdown',
      name: 'description',
      label: 'Description',
      description:
        'Builder description displayed at the top of the experience, if not populated the description will not be rendered',
    },
    {
      component: 'text',
      name: 'ctaTitle',
      label: 'CTA Label',
      description:
        'Text shown on the CTA button at the top of the experience, by clicking it the customer will scroll down to the bundle experience, if not populated the button will not be rendered',
    },
    {
      component: 'markdown',
      name: 'disclaimer',
      label: 'Header Disclaimer',
      description:
        'Message shown at the top of the experience, if not populated the disclaimer will not be rendered',
    },
    {
      component: 'image',
      name: 'desktopBgImage',
      label: 'Desktop Background Image',
      description:
        'Background image to be shown on the header of the builder experience on desktop resolution, recommended image size 2160px x 800px',
    },
    {
      component: 'image',
      name: 'mobileBgImage',
      label: 'Mobile Background Image',
      description:
        'Background image to be shown on the header of the builder experience on mobile resolution, recommended image size 1152px x 1152px',
    },
    {
      component: 'toggle',
      name: 'darkText',
      label: 'Text Color',
      description:
        'Defines the text color on the builder header when an background image is populated',
      toggleLabels: {
        true: 'Dark',
        false: 'Light',
      },
      defaultValue: true,
    },
    {
      component: 'toggle',
      name: 'hideHeader',
      label: 'Hide Header on Scroll',
      description:
        'If enabled, the header will be hidden when the customer scrolls down, and will be shown again when the customer scrolls up, only in the page this experience is visible',
      defaultValue: true,
    },
    {
      component: 'toggle',
      name: 'enableUrlHistory',
      label: 'Enable URL history',
      description:
        'Saves the content on the URl, so the customer can share it or come back to the state of the experience they left when moving to a PDP',
      defaultValue: false,
    },
    {
      component: 'text',
      name: 'collection',
      label: 'Collection Handle',
      description:
        "Handle of the collection that want's to be used to load the products",
      defaultValue: 'all-products',
    },
    {
      name: 'enableLoadMore',
      component: 'toggle',
      label: 'Enable Load More',
      defaultValue: true,
    },
    {
      name: 'scrollThreshold',
      component: 'number',
      label: 'Scroll Threshold',
      description:
        'What percentage of the page has the customer to scroll before loading more products?, recommended value 60. If this is set to 0 (zero) infinite scrolling will not be enabled and a "Load more" button will appear at the bottom of the product grid',
      defaultValue: 80,
    },
    {
      name: 'heroProduct',
      component: 'productSearch',
      label: 'Hero Product',
      description:
        'Set up a product that will be always present on the customers bundle, this product cannot be removed nor changed by the customer',
    },
    {
      component: 'group-list',
      name: 'filters',
      label: 'Filters',
      fields: [
        {
          component: 'text',
          name: 'name',
          label: 'Name',
          description:
            'Filter name shown to the customer on the builder experience',
        },
        {
          component: 'text',
          name: 'value',
          label: 'Filter Tag',
          description:
            "Reference of the tag that will be used for filtering the products, this value is case sensitive and should match the product's tag",
          defaultValue: 'Start building your bundle',
        },
      ],
    },
    {
      component: 'text',
      name: 'defaultBundleMessage',
      label: 'Default Bundle Message',
      description: 'Message shown to the customer when the bundle is empty',
    },
    {
      component: 'group-list',
      name: 'bundleSteps',
      label: 'Bundles Steps',
      fields: [
        {
          component: 'number',
          name: 'discountValue',
          label: 'Discount Value',
          defaultValue: 0,
          description:
            'If value is over 0, and the number of items on the bundles matches with this step, a discount will be applied to all the items added from the bundle',
        },
        {
          component: 'text',
          name: 'stepInfo',
          label: 'Step Info',
          description:
            'Message shown to the customer when the current step is reached, used to display current current and next tier discount information',
        },
        {
          component: 'toggle',
          name: 'enableFreeGift',
          label: 'Enable Free Gift',
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
          description:
            'If enabled, when the customer reaches this step a free gift will be added to their cart with their bundle',
        },
        {
          component: 'productSearch',
          name: 'bundleFreeGiftProduct',
          label: 'Bundle Free Gift',
          description: 'Product that will be added to the cart as a free gift if the customer reaches the current step',
        }
      ],
      validate: {
        maxItems: 4,
        required: true,
      },
    },
  ],
};