import { useEffect, useRef } from 'react';
import {
  useCart,
  useCartTotals,
  useCartCheckout,
  useCartCount,
  useCartRemoveItem,
  useCartRemoveItems,
  useCartUpdateItem,
  useCartUpdateItems,
  useSettings,
  useCountry,
} from '@backpackjs/storefront';

import { useCartDiscountPrices } from '@hooks/useCartDiscountPrices';

import SideCartItem from './SideCartItem';
import SideCartBundleItem from './SideCartBundleItem';
import FreeShipping from './FreeShipping';
import { formatMoney } from '../../utilities/Helper';
import { IconClose } from '@/snippets/index';
import MetafieldUpsell from './MetafieldUpsell';
import BeamNonprofitWidget from '../beam/BeamNonprofitWidget';
import PromoSlider from './PromoSlider';
import { GWPHandler } from '../cart/GWPHandler';
import { BEAM_API_KEY, BEAM_BASE_URL, BEAM_STORE_ID } from '../beam/constants';

function SideCart(props) {
  const cart = useCart();
  const cartCount = useCartCount();
  const cartTotals = useCartTotals();
  const { discountAmount } = useCartDiscountPrices();
  const siteSettings = useSettings();
  const { cartCheckout } = useCartCheckout();
  const { cartRemoveItem } = useCartRemoveItem();
  const { cartRemoveItems } = useCartRemoveItems();
  const { cartUpdateItem } = useCartUpdateItem();
  const { cartUpdateItems } = useCartUpdateItems();
  const { country } = useCountry();
  const countryCode = country?.isoCode || 'US';
  const isUs = countryCode === 'US';
  const currencySymbol = country?.currency?.symbol || '$';
  const isBrowser = typeof window !== 'undefined';
  const enablePromoSlider = siteSettings?.sideCart?.promotionalSlider?.enable;
  const promoSliderSettings = siteSettings?.sideCart?.promotionalSlider;

  if (isBrowser) {
    localStorage.setItem('cartData', JSON.stringify(cart));
  }

  const gwpItems = cart?.lines?.filter((line) =>
    line.attributes.find((attr) => attr.key === '_gwp')
  );
  const hasBundleItems = cart?.lines?.some((line) =>
    line.attributes.find((attr) => attr.key === '_bundleId')
  );

  let lines = cart?.lines?.filter(
    (line) =>
      !line.attributes.find((attr) => ['_gwp'].includes(attr.key))
  );
  const compareAtPriceTotal = cart?.lines?.reduce((accumulator, cartItem) => {
    let compareAmount =
      parseFloat(
        !!cartItem?.variant?.compareAtPriceV2?.amount &&
          cartItem?.variant?.compareAtPriceV2?.amount !== '0.0'
          ? cartItem?.variant?.compareAtPriceV2?.amount
          : cartItem?.variant?.priceV2.amount
      ) * cartItem.quantity;
    if (cartItem?.sellingPlanAllocation && compareAmount == 0) {
      compareAmount =
        parseFloat(cartItem?.variant?.priceV2?.amount) * cartItem.quantity;
    }
    return compareAmount > 0 ? accumulator + compareAmount : accumulator;
  }, 0);

  const discountHighlight =
    siteSettings?.discountHighlight?.enableDiscountHighlight === true &&
    siteSettings?.discountHighlight?.cartDiscountMessage !== '' &&
    compareAtPriceTotal - cartTotals?.subtotal + discountAmount > 0;

  if (hasBundleItems) {
    // Morphs the lines array to identify bundle items

    lines = lines.map((line, index) => {
      const newLine = { ...line };

      if (line.attributes.find((attr) => attr.key === '_bundleId')) {
        newLine.isBundle = true;
        newLine.bundleItems = cart?.lines.filter((lineItem) => {
          return lineItem.attributes.some((attr) => attr.key === '_bundleId');
        });
      }

      return newLine;
    });
  }

  const filteredCartCount = lines?.length || 0;
  const cartCountHeader =
    filteredCartCount === 0 ? filteredCartCount : cartCount;
  const subscriptions = cart?.lines?.filter(
    (line) => !!line?.sellingPlanAllocation
  );

  const overLimitLines = lines?.filter((line) => line.quantity > 5);

  const { sideCartOpen, toggleSideCart } = props;
  const closeSideCartBtn = useRef(null);

  const closeSideCart = (evt) => {
    if (
      evt.target.classList.contains('sidecartOpen') &&
      closeSideCartBtn.current
    ) {
      closeSideCartBtn.current.click();
    }
  };

  const updateBeamData = async () => {
    if (cart?.id) {
      if (cart?.id) {
        const beamConfig = {
          storeId: BEAM_STORE_ID,
          apiKey: BEAM_API_KEY,
          baseUrl: BEAM_BASE_URL,
          domain: "briogeohair.com",
        }

        const data = {
          cartId: cart?.id?.replace('gid://shopify/Cart/', ''),
          currencyCode: cart.estimatedCost.totalAmount.currencyCode,
          itemCount: cartCount,
          subtotal: Number.parseFloat(cart.estimatedCost.totalAmount.amount),
          content: {
            items: cart.lines.map((line) => {
              return {
                localAmount: Number.parseFloat(line.estimatedCost.totalAmount.amount),
                remoteProductIdentifier: line.variant.product.id.replace('gid://shopify/Product/', ''),
              }
            })
          }
        };

        if(typeof window !== 'undefined' && window.beamUpdateCart) {
          window.beamUpdateCart(beamConfig, data);
        }
      }
    }
  };

  const buildCartItems = () => {
    let skipItem = 0;
    const newLines = lines.map((item, index) => {
      if (skipItem > 0) {
        skipItem = skipItem - 1;
        return null;
      }

      if (item.isBundle) {
        skipItem = item.bundleItems.length - 1;

        return (
          <div key={item.id}>
            <SideCartBundleItem
              item={item}
              key={`lineItem-${index}`}
              isUs={isUs}
            />
          </div>
        );
      }

      return (
        <div key={item.id}>
          <SideCartItem
            item={item}
            key={`lineItem-${index}`}
            cartRemoveItem={cartRemoveItem}
            cartUpdateItem={cartUpdateItem}
            isUs={isUs}
          />
        </div>
      );
    });

    return newLines;
  };

  useEffect(() => {
    PubSub.publish('VIEW_SIDECART', sideCartOpen);
  }, [sideCartOpen]);

  useEffect(() => {
    const { body } = document;
    body.addEventListener('click', closeSideCart);

    return () => {
      body.removeEventListener('click', closeSideCart);
    };
  }, []);

  useEffect(() => {
    // if user switches countries and they have subscriptions, clear the cart
    if (country && !isUs && subscriptions?.length) {
      const removeCartItems = async () => {
        const lineIds = cart?.lines?.map(({ id }) => id);
        await cartRemoveItems({ lineIds });
      };
      removeCartItems();
    }
  }, [isUs, country]);

  useEffect(() => {
    if (overLimitLines?.length) {
      const updateLimitLines = async () => {
        const lineItems = overLimitLines?.map(({ id, sellingPlanId }) => {
          return {
            lineId: id,
            quantity: 1,
            sellingPlanID: sellingPlanId,
          };
        });
        await cartUpdateItems({ lines: lineItems });
      };
      updateLimitLines();
    }

    updateBeamData();
  }, [
    gwpItems?.length,
    lines?.length,
    cartCount,
    isUs,
  ]);

  useEffect(() => {
    if (cart) {
      const limit = 5;
      const lineOverLimit = cart.lines.find((line) => line.quantity > limit);
      if (lineOverLimit) {
        cartUpdateItem({
          lineId: lineOverLimit.id,
          quantity: limit,
        });
      }
    }
  }, [cart?.updatedAt]);

  useEffect(() => {
    // Track cart creation event on VWO
    const storedCartId = localStorage.getItem('VWO_cart_id');

    if (
      typeof cart?.id !== 'undefined' &&
      cart?.id !== storedCartId &&
      cartCount &&
      cartCount > 0
    ) {
      localStorage.setItem('VWO_cart_id', cart?.id);

      if (isBrowser) {
        window._vis_opt_queue = window._vis_opt_queue || [];
        window._vis_opt_queue.push(() => {
          window._vis_opt_goal_conversion(200);
        });
      }
    }
  }, [cartCount]);

  return (
    <div className="block">
      <div
        id="sideCart"
        className={`fixed top-0 right-0 z-[2002] flex h-full w-full max-w-[390px] flex-col justify-between bg-white transition-all duration-300${
          sideCartOpen
            ? ' opacity-1 translate-x-0'
            : ' translate-x-full opacity-0'
        }`}
      >
        <div className="flex items-center justify-between border-b border-grey-9 px-4 py-3">
          <button
            type="button"
            onClick={(evt) => {
              toggleSideCart(evt);
            }}
            ref={closeSideCartBtn}
          >
            <IconClose />
          </button>
          <div className="m-0 flex-grow px-4 font-gothamBold text-base">
            Your Cart
          </div>
          <div className="text-sm">
            {cartCountHeader} item{cartCountHeader === 1 ? '(s)' : 's'}
          </div>
        </div>
        {isUs && filteredCartCount > 0 && (
          <FreeShipping
            cartTotals={cartTotals}
            cartCount={cartCount}
            discountAmount={discountAmount}
          />
        )}
        { filteredCartCount > 0 && enablePromoSlider == true && (
          <PromoSlider settings={promoSliderSettings} />
        )}
        <div className="flex-1 overflow-y-auto overflow-x-hidden">
          {filteredCartCount === 0 && (
            <div className="py-6">
              <p className="m-0 text-center font-gothamBold text-base">
                Oops! Your cart is currently empty.
              </p>
            </div>
          )}
          {filteredCartCount > 0 && buildCartItems()}
          {<GWPHandler gwpItems={gwpItems} cartLines={lines} />}
          {filteredCartCount > 0 && <BeamNonprofitWidget />}
          {filteredCartCount > 0 && <MetafieldUpsell cartItems={lines} />}
        </div>

        <div className="border-t border-black px-4 py-3.5 text-sm">
          {discountHighlight && (
            <>
              <div className="flex items-center justify-between font-gothamBold">
                <span className="pr-4">Before Savings</span>
                <div>
                  {formatMoney(compareAtPriceTotal * 100 || 0)?.replace(
                    '$',
                    currencySymbol
                  )}
                </div>
              </div>
              <div className="flex items-center justify-between font-gothamBold">
                <span className="pr-4">
                  {siteSettings?.discountHighlight?.cartDiscountMessage}
                </span>
                <div className="text-primary-2">
                  -
                  {`${currencySymbol}${(
                    compareAtPriceTotal -
                    cartTotals?.subtotal +
                    discountAmount
                  )
                    .toFixed(2)
                    .replace('.00', '')}`}
                </div>
              </div>
            </>
          )}
          <div className="flex items-center justify-between font-gothamBold">
            <span className="pr-4">Subtotal</span>
            <div>
              <span>
                {`${currencySymbol}${(
                  cartTotals?.subtotal - discountAmount || 0
                )
                  .toFixed(2)
                  .replace('.00', '')}`}
              </span>
            </div>
          </div>
          <div className="mt-2">
            <button
              type="button"
              className="button button--checkout w-full"
              onClick={cartCheckout}
            >
              checkout
            </button>
          </div>
          <div className="whitespace-normal pt-2 text-center">
            <span className="text-xs">
              Any discounts, shipping & taxes calculated at checkout
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideCart;
