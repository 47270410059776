import { useState, useEffect } from 'react';

export function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState('down');
  let lastScrollTop = 0;

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;

      if (currentScrollTop > lastScrollTop + 50) {
        setScrollDirection('down');
        lastScrollTop = currentScrollTop;
      } else if (currentScrollTop < lastScrollTop - 50) {
        setScrollDirection('up');
        lastScrollTop = currentScrollTop;
      }

    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return scrollDirection;
}
