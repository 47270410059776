import { useSettings, useCart } from '@backpackjs/storefront';
import { formatMoney } from '../../utilities/Helper.js';
import { useEffect, useState } from 'react';

function FreeShipping({ cartTotals, discountAmount }) {
  const settings = useSettings();
  const cart = useCart();
  const [shipScoutSettings, setShipScoutSettings] = useState(null);
  const isBrowser = typeof window !== 'undefined';
  const shipScoutThreshold = shipScoutSettings?.freeShippingThresholdCents / 100;
  const hasSubscription = cart?.lines.find(
    (line) => !!line?.sellingPlanAllocation
  );
  const shippingSettings = settings?.sideCart?.freeShippingThreshold || {};

  const threshold = shipScoutThreshold || shippingSettings?.Threshold || 0;
  if (threshold <= 0) {
    return null;
  }
  const cart_total = cartTotals?.subtotal
    ? cartTotals.subtotal - discountAmount
    : 0;
  const leftAmount = Math.max(0, threshold - cart_total).toFixed(2);
  let leftAmountFormat = formatMoney(leftAmount).split('.');
  if (leftAmountFormat.length === 1) {
    leftAmountFormat = `${leftAmountFormat[0]}.00`;
  } else {
    leftAmountFormat = leftAmountFormat.join('.');
  }

  const enableSubscriptionShipping = shippingSettings?.enableSubscriptionText;
  const useSubscriptionMessage =
    hasSubscription &&
    enableSubscriptionShipping &&
    shippingSettings?.subscriptionThresoldText;
  const useSubscriptionShipping = hasSubscription && enableSubscriptionShipping;
  const information = shippingSettings?.information
    ? shippingSettings?.information.replace('[TOTAL]', leftAmountFormat)
    : '';
  const meetMessage = useSubscriptionMessage
    ? shippingSettings?.subscriptionThresoldText
    : shippingSettings?.message || '';
  let progress = Math.min(Math.floor((100 * cart_total) / threshold), 100);

  const shippingMet = leftAmount <= 0 || useSubscriptionShipping;
  if (useSubscriptionShipping) progress = 100;

  useEffect(() => {
    if (isBrowser && window._shipScout && shipScoutSettings == null) {
      window._shipScout.push((settings) => {
        setShipScoutSettings(settings);
      })
    }
  }, [cartTotals]);

  return (
    <div className="bg-grey-9 px-5 py-5 text-center text-sm">
      {!shippingMet && (
        <div
          className="px-4"
          dangerouslySetInnerHTML={{ __html: information }}
        />
      )}
      {shippingMet && (
        <div
          className="px-4"
          dangerouslySetInnerHTML={{ __html: meetMessage }}
        />
      )}
      <div className="shipping-progress-bar mt-2 overflow-hidden rounded-[10px] border border-black">
        <div
          className={`shipping-progress-bar-inner bg-quinary-2 py-1 rounded-[10px]${
            progress < 100 ? ' rounded-r-none' : ''
          }`}
          style={{ width: `${progress}%`, transition: 'all 1s' }}
        />
      </div>
    </div>
  );
}

export default FreeShipping;
