import Link from 'next/link';
import { newLineToBr } from '@/utils/Helper';

export function ImageWithText({ cms }) {
  const desktopImage = cms?.image_d ? cms.image_d : cms.image_m;
  const mobileImage = cms?.image_m ? cms.image_m : cms.image_d;
  const desktopVideo = cms?.video_d ? cms.video_d : cms.video_m;
  const mobileVideo = cms?.video_m ? cms.video_m : cms.video_d;

  const getVideoExt = (url) => {
    if (url.match(/\.webm/i)) {
      return `video${url
        .match(/\.webm/i)[0]
        .replace('.', '/')
        .toLowerCase()}`;
    }
    if (url.match(/\.mp4/i)) {
      return `video${url.match(/\.mp4/i)[0].replace('.', '/').toLowerCase()}`;
    }
    return '';
  };

  const getCTAclass = (style) => {
    if (style === 'link') return 'focus-link';
    if (style === 'white-link') return 'focus-link-white';
    if (style === 'primary') return 'no-underline button button--primary';
    if (style === 'secondary') return 'no-underline button button--secondary';
    if (style === 'tertiary') return 'no-underline button button--tertiary';
    return '';
  };

  return (
    <div
      id={`${ImageWithText.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
      data-comp={ImageWithText.displayName}
      className={`image-w-text flex flex-wrap ${cms.bgColor} ${cms.fgColor}`}
    >
      <style>
        {`#${ImageWithText.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${ImageWithText.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
              margin-top: ${cms.desktopSpacing}px;
            }
          }`}
      </style>
      <div
        className={`w-full lg:w-1/2 ${
          cms.image_first ? 'lg:order-first' : 'lg:order-last'
        }`}
      >
        <div className="lg:relative lg:w-full lg:pt-[100%]" style={{
          aspectRatio: cms.aspect_ratio_m
        }}>
          {desktopVideo && (
            <video
              className="absolute inset-0 hidden h-full w-full object-cover lg:block"
              playsInline
              preload
              loop
              autoPlay
              muted
            >
              <source
                src={desktopVideo}
                type={`${getVideoExt(desktopVideo)}`}
              />
              Video Not Found
            </video>
          )}
          {mobileVideo && (
            <video
              className="w-full lg:hidden"
              playsInline
              preload
              loop
              autoPlay
              muted
            >
              <source src={mobileVideo} type={`${getVideoExt(mobileVideo)}`} />
              Video Not Found
            </video>
          )}
          {!desktopVideo && desktopImage && (
            <img
              className="absolute inset-0 hidden h-full w-full object-cover lg:block"
              src={`${desktopImage.src}?quality=75`}
              alt={cms?.image_alt}
              loading="lazy"
            />
          )}
          {!mobileVideo && mobileImage && (
            <img
              className="w-full lg:hidden"
              src={`${mobileImage.src}?quality=75`}
              alt={cms?.image_alt}
              loading="lazy"
            />
          )}
        </div>
      </div>
      <div
        className={`w-full lg:w-1/2 ${
          cms.image_first ? 'lg:order-last' : 'lg:order-first'
        } px-4 py-12 lg:flex lg:items-center lg:px-12 lg:py-10 2xl:px-[126px]`}
      >
        <div
          className={`${cms.text_align} mx-auto max-w-[440px] lg:ml-0 lg:text-left`}
        >
          {cms?.intro && <h6 className="mb-4">{cms.intro}</h6>}
          {cms?.heading && <h2 className="text-header mb-4">{cms.heading}</h2>}
          {cms?.body && (
            <div
              className="mb-6 text-base font-light 2xl:mb-8 2xl:text-[18px] 2xl:leading-[26px]"
              dangerouslySetInnerHTML={{ __html: newLineToBr(cms.body) }}
            />
          )}
          {cms?.cta_link?.text && cms?.cta_link?.url && (
            <Link href={cms.cta_link.url}>
              <a
                className={`${getCTAclass(
                  cms.cta_style
                )} inline-block cursor-pointer text-base`}
              >
                {cms.cta_link.text}
              </a>
            </Link>
          )}
          {cms?.npr_link && (
            <iframe
              src={cms.npr_link}
              title="NPR embedded audio player"
              width="100%"
              height="200"
              frameBorder="0"
              scrolling="no"
              className="mt-6 2xl:mt-8"
            />
          )}
        </div>
      </div>
    </div>
  );
}

ImageWithText.displayName = 'ImageWithText';

ImageWithText.Schema = {
  category: 'Modules',
  label: ImageWithText.displayName,
  key: 'ImageWithText',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      name: 'bgColor',
      label: 'Background Color',
      component: 'select',
      description:
        'For section background color, select one from preselected brand colors.',
      options: [
        { label: 'White', value: 'bg-white' },
        { label: 'Grey', value: 'bg-grey-1' },
        { label: 'Light Grey', value: 'bg-grey-9' },
        { label: 'Blue 1', value: 'bg-quinary-1' },
        { label: 'Blue 2', value: 'bg-quinary-2' },
        { label: 'Blue 3', value: 'bg-light-blue' },
        { label: 'Cream 1', value: 'bg-tertiary-2' },
        { label: 'Cream 2', value: 'bg-senary-4' },
        { label: 'Purple', value: 'bg-tertiary-1' },
        { label: 'Green', value: 'bg-quaternary-1' },
        { label: 'Pink 1', value: 'bg-primary-2' },
        { label: 'Pink 2', value: 'bg-secondary-1' },
        { label: 'Yellow', value: 'bg-yellow' },
        { label: 'Orange', value: 'bg-secondary-2' },
        { label: 'Seafoam 1', value: 'bg-quaternary-2' },
        { label: 'Seafoam 2', value: 'bg-senary-3' },
        { label: 'Air Dry Cream', value: 'bg-stylers-1' },
        { label: 'Sculpting Cream', value: 'bg-stylers-2' },
        { label: 'Texture Foam', value: 'bg-stylers-3' },
        { label: 'Sleek Stick', value: 'bg-stylers-4' }
      ],
      defaultValue: 'bg-white',
    },
    {
      name: 'fgColor',
      label: 'Foreground Color',
      component: 'select',
      description:
        'For section foreground color, select one from preselected brand colors.',
      options: [
        { label: 'White', value: 'text-white' },
        { label: 'Dark', value: 'text-grey-1' },
      ],
      defaultValue: 'text-grey-1',
    },
    {
      name: 'image_first',
      label: 'Image First?',
      component: 'toggle',
      description: 'This toggle effects on desktop view only',
      toggleLabels: {
        true: 'Yes',
        false: 'No',
      },
      defaultValue: true,
    },
    {
      name: 'image_d',
      label: 'Image (Desktop)',
      component: 'image',
      description: 'Recommended Image Size: 1440px x 1440px',
    },
    {
      name: 'image_m',
      label: 'Image (Mobile)',
      component: 'image',
      description: 'Recommended Image Size: 1536px x 1114px',
    },
    {
      name: 'image_alt',
      label: 'Image alt text',
      component: 'text',
    },
    {
      name: 'video_d',
      label: 'Video URL (Desktop)',
      component: 'text',
      description:
        'Video media will need to be provided in 2 formats: webm as default and mp4 for IOS/safari fall-back',
    },
    {
      name: 'video_m',
      label: 'Video URL (Mobile)',
      component: 'text',
      description:
        'Video media will need to be provided in 2 formats: webm as default and mp4 for IOS/safari fall-back',
    },
    {
      name: 'aspect_ratio_m',
      label: 'Image Aspect Ratio (Mobile)',
      description: 'Required only when a video is set on mobile layout. Width divided by height, e.g. 16:9 image is "1.78"',
      component: 'number',
    },
    {
      name: 'intro',
      label: 'Intro Title',
      component: 'text',
    },
    {
      name: 'heading',
      label: 'Heading',
      component: 'text',
    },
    {
      name: 'body',
      label: 'Body',
      component: 'textarea',
    },
    {
      name: 'cta_link',
      label: 'CTA Link',
      component: 'link',
    },
    {
      name: 'cta_style',
      label: 'CTA Link Style',
      component: 'select',
      options: [
        { label: 'Primary', value: 'primary' },
        { label: 'Secondary', value: 'secondary' },
        { label: 'Tertiary', value: 'tertiary' },
        { label: 'Focus Link', value: 'link' },
        { label: 'Focus Link White', value: 'white-link' },
      ],
      defaultValue: 'link',
    },
    {
      name: 'npr_link',
      label: 'NPR Link',
      component: 'text',
      description:
        'Example for NPR link is https://www.npr.org/player/embed/900303741/900327675',
    },
    {
      name: 'text_align',
      label: 'Mobile Text Align',
      component: 'select',
      options: [
        { label: 'Left Align', value: 'text-left' },
        { label: 'Center Align', value: 'text-center' },
      ],
      defaultValue: 'text-center',
    },
  ],
};
